import Head from 'next/head';
import { useRouter } from "next/router";

export default function NextHead({
    title,
    description,
    ogImage,
    children
}) {
    const router = useRouter();
    const canonicalUrl = (`${process.env.NEXT_PUBLIC_DOMAIN}` + (router.asPath === "/" ? "" : router.asPath)).split("?")[0];

    return (
        <Head>
            <title>{title || "Proptechpedia"}</title>
            <meta name="description" content={description || ""} />
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            {/* open graph data */}
            <meta property="og:title" content={title || "Proptechpedia"} />
            <meta property="og:image" content={ogImage || "/png/proptechpedia-stamp.png"} />
            <meta property="og:description" content={description || ""} />
            <meta property="og:url" content={canonicalUrl} />

            <link rel="icon" href="/favicon.ico" />
            <link rel="canonical" href={canonicalUrl} />
            {children}
        </Head>
    )
}
