import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import useNextRouterHook from "../../hooks/useNextRouterHook";

export default function Footer() {
  const routerTo = useNextRouterHook();
  const { t } = useTranslation();
  const mediaNavItems = [{ url: 'https://twitter.com/proptechpedia', icon: '/svg/social-media/twitter.svg', name: 'Twitter' }, { url: 'https://www.linkedin.com/company/proptechpedia', icon: '/svg/social-media/linkedin.svg', name: 'LinkedIn' }];

  return (
    <footer>
      <div>
        <Image src='/svg/brand/proptechpedia-imagotype.svg' className='proptechpedia-imagotype' alt='' width={'210rem'} height={'210rem'}  />
      </div>

      <section className='footer-content'>
        <nav>
          <ul className='main-nav'>
            <li className='nav-items-box'>
              {/* <span className='nav-item' onClick={() => routerTo('/sobre-nosotros')}>{t('footer.about_us')}</span> */}
              <span className='nav-item' onClick={() => routerTo('/politicas/cookies')}>{t('legals.cookies.title')}</span>
            </li>
            <li className='nav-items-box'>
            </li>
            <li className='nav-items-box'>
              <span className='nav-item' onClick={() => routerTo('/politicas/condiciones-de-uso')}>{t('legals.conditions.title')}</span>
            </li>
            <li className='nav-items-box'>
            </li>
            <li className='nav-items-box'>
              <span className='nav-item' onClick={() => routerTo('/politicas/privacidad')}>{t('legals.privacy.title')}</span>
            </li>
          </ul>
        </nav>

        <div className='bottom-box'>
          <p>
            Copyright 2022 <br />
            Todos los derechos reservados The Fringe Labs S.L.
          </p>

          <nav>
            <ul className='media-nav'>
              {mediaNavItems.map((social) => <li key={social.url}>
                <a href={`${social.url}`} target='_blank'>
                  <Image src={social.icon} alt={`${t("alt_text.social_media_links")} ${social.name}`} width={'35rem'} height={'35rem'}/>
                </a>
              </li>
              )}
            </ul>
          </nav>
        </div>
      </section>

    </footer>
  )
}
