import { useState, useEffect } from 'react';

import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { Drawer, IconButton } from "@mui/material";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import AuthService from '../services/authService';

export default function Header(props = {}) {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem('idToken');
    if (token !== null) {
      setUserSignedIn(true);
    } else {
      setUserSignedIn(false);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (screen.width <= 768) {
        setIsScreenSmall(true);
      }
    }
  }, []);

  async function signOut() {
    const signOutResponse = await AuthService.handleSignOut();
    if (signOutResponse === AuthService.responses.success) {
      setUserSignedIn(false);
      props.routerTo('/');
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const signInButton = (
    <button className='action' onClick={() => props.routerTo("/login")}>{t("header.sign_in")}</button>
  );

  const signOutButton = (
    <button className='action' onClick={signOut}>{t("header.sign_out")}</button>
  );

  const signUpButton = (
    userSignedIn ? <></> : <button onClick={() => props.routerTo("/registro")} className='button-filled'>{t("actions.sign_startup_up")}</button>
  )

  const actionButtons = (
    <>
      <button className='action' onClick={() => props.routerTo("/categorias")}>{t("categories.categories")}</button>
      <button className='action' onClick={() => props.routerTo("/empresas")}>{t("header.companies")}</button>
      {userSignedIn ? signOutButton : signInButton}
      {userSignedIn ? <button onClick={() => props.routerTo("/perfil/usuario")} className='action'>{t("profile.title")}</button> : <></>}
    </>
  )

  const drawerMenu = (
    <div className='header-action-drawer-area'>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuRoundedIcon sx={{ fontSize: '45px', color: '#4ebab8', mr: 0.5 }} />
      </IconButton>
      {signUpButton}
      <Drawer
        anchor={"top"}
        open={openDrawer}
        onClose={toggleDrawer(false)}
      >
        <div className='header-action-menu'>
          {actionButtons}
        </div>
      </Drawer>
    </div>
  )

  return (
    <>
      <header>
        <div className='proptechpedia-logotype'>
          <Image src='/svg/brand/proptechpedia-logotype.svg' alt={t('alt_text.logo_home')}
            onClick={() => props.routerTo("/")}
            width={'300rem'} height={'300rem'} />
        </div>
        {(isScreenSmall) ? drawerMenu : (
          <div className='header-action-area'>
            {actionButtons}
            {signUpButton}
          </div>
        )}
      </header>
    </>
  )
}
