import { Auth } from 'aws-amplify';

let user = {};
const tokenKeyNames = { idToken: 'idToken', refreshToken: 'refreshToken', userId: 'userId' };
const exceptions = {
    setNewPassword: 'NEW_PASSWORD_REQUIRED',
    notAuthorized: 'NotAuthorizedException',
    usernameExists: 'UsernameExistsException',
    userNotConfirmed: 'UserNotConfirmedException',
    userNotFound: 'UserNotFoundException',
    attemptLimitExceeded: 'LimitExceededException',
    codeMismatch: 'CodeMismatchException',
    expiredCode: 'ExpiredCodeException'
};

let defaultLocalStorage;
if (typeof window !== 'undefined') {
    defaultLocalStorage = { ...localStorage };
}

function setInLocalStorage(keys, values) {
    for (let i = 0; i < keys.length; i++) {
        localStorage.setItem(keys[i], values[i]);
    }
}

function removeFromLocalStorage(keys) {
    for (let i = 0; i < keys.length; i++) {
        localStorage.removeItem(keys[i]);
    }
}

function setDefaultLocalStorage() {
    localStorage.clear();
    Object.entries(defaultLocalStorage).forEach(([key, value]) => {
        localStorage.setItem(key, value);
    });
}

export default class AuthService {
    static responses = {
        failed: 0,
        success: 1,
        newPasswordRequired: 2,
        notAuthorized: 3,
        userExists: 4,
        userNotConfirmed: 5,
        userNotFound: 6,
        attemptLimitExceeded: 7,
        codeMismatch: 8,
        expiredCode: 9
    }

    static handleSignUp = async (userInput) => {
        try {
            const { user } = await Auth.signUp({
                username: userInput.email,
                password: userInput.password,
                attributes: {
                    email: userInput.email,
                }
            });
            return this.responses.success;
        } catch (error) {
            if (error.name === exceptions.usernameExists) {
                return this.responses.userExists;
            } else {
                return this.responses.failed;
            }
        }
    }

    static handleSignIn = async (userInput) => {
        try {
            user = await Auth.signIn(userInput.email, userInput.password);
            if (user.challengeName === exceptions.setNewPassword) {
                return this.responses.newPasswordRequired;
            } else {
                const keys = [tokenKeyNames.idToken, tokenKeyNames.refreshToken, tokenKeyNames.userId];
                const values = [user.signInUserSession.idToken.jwtToken, user.signInUserSession.refreshToken.token, user.attributes.sub];
                setInLocalStorage(keys, values);
                return this.responses.success;
            }
        } catch (error) {
            if (error.name === exceptions.userNotConfirmed) {
                return this.responses.userNotConfirmed;
            } else if (error.name === exceptions.userNotFound) {
                return this.responses.userNotFound;
            } else if (error.name === exceptions.notAuthorized) {
                return this.responses.notAuthorized
            } else {
                return this.responses.failed;
            }
        }
    }

    static handleSetPassword = async (userInput) => {
        try {
            await Auth.completeNewPassword(
                user,
                userInput.newPassword
            );
            const keys = [tokenKeyNames.idToken, tokenKeyNames.refreshToken, tokenKeyNames.userId];
            const values = [user.signInUserSession.idToken.jwtToken, user.signInUserSession.refreshToken.token, user.username]
            setInLocalStorage(keys, values);
            return this.responses.success;
        } catch (error) {
            if (error.name === exceptions.notAuthorized) {
                return this.responses.notAuthorized;
            }
            return this.responses.failed;
        }
    }

    static sendPasswordRecoveryCode = async (userInput) => {
        try {
            const response = await Auth.forgotPassword(userInput.email);
            return this.responses.success;
        } catch (error) {
            if (error.name === exceptions.userNotConfirmed) {
                return this.responses.userNotConfirmed;
            } else if (error.name === exceptions.userNotFound) {
                return this.responses.userNotFound;
            } else {
                return this.responses.failed;
            }
        }
    }

    static handlePasswordRecovery = async (userInput) => {
        try {
            const response = await Auth.forgotPasswordSubmit(userInput.email, userInput.recoveryCode, userInput.newPassword);
            return this.responses.success;
        } catch (error) {
            if (error.name === exceptions.codeMismatch) {
                return this.responses.codeMismatch;
            } else if (error.name === exceptions.attemptLimitExceeded) {
                return this.responses.attemptLimitExceeded;
            } else if (error.name === exceptions.expiredCode) {
                return this.responses.expiredCode;
            } else {
                return this.failed;
            }
        }
    }

    static handleChangePassword = async (userInput) => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            const response = await Auth.changePassword(user, userInput.password, userInput.newPassword);
            return this.responses.success;
        } catch (error) {
            if (error.name === exceptions.notAuthorized) {
                return this.responses.notAuthorized;
            } else if (error.name === exceptions.attemptLimitExceeded) {
                return this.responses.attemptLimitExceeded;
            } else {
                return this.failed;
            }
        }
    }

    static getCurrentUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const keys = [tokenKeyNames.idToken, tokenKeyNames.refreshToken];
            const values = [user.signInUserSession.idToken.jwtToken, user.signInUserSession.refreshToken.token];
            removeFromLocalStorage(keys);
            setInLocalStorage(keys, values);
            return user;
        } catch (error) {
            return undefined;
        }
    }

    static handleSignOut = async () => {
        try {
            await Auth.signOut();
            const keys = [tokenKeyNames.idToken, tokenKeyNames.refreshToken, tokenKeyNames.userId];
            removeFromLocalStorage(keys);
            return this.responses.success;
        } catch (error) {
            return this.responses.failed;
        }
    }

}